@import '../../../../scss/variables.scss';
@import '../../../../scss/typography.scss';

.detailed-cell {
  display: flex;
  flex-direction: column;

  flex: 1; // NEW: Makes the detailed-cell to take full-width of parent container
  // width: 403px;
  gap: 12px;
  padding: 16px;
  background-color: $neutral-00;
  border: 1px solid $neutral-40;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    border: 1px solid $neutral-30;
    box-shadow: 2px 2px 15px 0px #928d8526;
  }

  &.selected {
    @extend .detailed-cell;
    background-color: $primary-blue-05;
    border-radius: 5px;
    outline: 2px solid $primary-blue-75;
    border-color: transparent; // Hide the inner border
    &:hover {
      outline: 2px solid $primary-blue-75;
      border-color: transparent; // Hide the inner border
    }

    &.disabled {
      @extend .detailed-cell;
      background-color: $neutral-30;
      outline: 2px solid $neutral-80;
      border-radius: 5px;
      cursor: not-allowed;

      &:hover {
        outline: 2px solid $neutral-80;
        box-shadow: none;
        border-color: transparent; // Hide the inner border
      }

      .detailed-cell_title {
        color: $neutral-100;
      }
      .detailed-cell_description {
        &.title {
          @include bodycopy-b3-semibold;
          color: $neutral-90;
          line-height: 100%;
        }
        &.regular {
          @include bodycopy-b3-regular;
          color: $neutral-80;
          line-height: 100%;
        }
        &.medium {
          @include bodycopy-b3-medium;
          color: $neutral-90;
          line-height: 100%;
        }
      }
    }

    .detailed-cell_title {
      color: $neutral-100;
    }
  }

  &.disabled {
    @extend .detailed-cell;
    background-color: $neutral-20;
    outline: 1px solid $neutral-40;
    border-radius: 5px;
    border-color: transparent; // Hide the inner border
    cursor: not-allowed;

    &:hover {
      outline: 1px solid $neutral-40;
      box-shadow: none;
      border-color: transparent; // Hide the inner border
    }

    .detailed-cell_title {
      color: $neutral-70;
    }

    .detailed-cell_description {
      &.title {
        @include bodycopy-b3-semibold;
        color: $neutral-60;
        line-height: 100%;
      }
      &.regular {
        @include bodycopy-b3-regular;
        color: $neutral-80;
        line-height: 100%;
      }
      &.medium {
        @include bodycopy-b3-medium;
        color: $neutral-60;
        line-height: 100%;
      }
    }
  }
}

.detailed-cell_text {
  @include bodycopy-b1-regular;
  color: $neutral-90;
  line-height: 100%;
}

.detailed-cell_title {
  @include header-h4-semibold;
  color: $neutral-90;
  line-height: 100%;
}

.detailed-cell_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.detailed-cell_description {
  &.title {
    @include bodycopy-b3-semibold;
    color: $neutral-90;
    line-height: 100%;
  }
  &.regular {
    @include bodycopy-b3-regular;
    color: $neutral-80;
    line-height: 100%;
  }
  &.medium {
    @include bodycopy-b3-medium;
    color: $neutral-90;
    line-height: 100%;
  }
}

.idented-area {
  display: flex;
  flex-direction: column;
  padding-left: 4px;
  gap: 12px;
}

.layout-area {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.layout {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.layout-details {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.image-wrap {
  display: grid;
  grid-auto-flow: row;
  grid-template: 1fr / 1fr 64px;
  gap: 16px;
  align-items: center;

  &.disabled {
    filter: opacity(0.4);
    img {
      filter: opacity(0.4);
    }
  }
}
