@import '../../../../scss/variables.scss';

.button-switcher {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  padding: 4px;
  gap: 12px;
  background-color: rgba(25, 30, 22, 0.3);
  border: 1px solid $primary-blue-05;
  border-radius: 6px;
  backdrop-filter: blur(10px);
  pointer-events: all;

  .button-switcher__button {
    padding: 8px;
    border: none;
    background-color: transparent;
    cursor: pointer;

    svg {
      width: 24px;
      height: 24px;

      path {
        fill: $neutral-00;
      }
    }

    &:hover {
      svg path {
        fill: $neutral-90;
      }
    }

    &:focus-visible {
      outline: none;
      border: 2px solid $accessibility;
      border-radius: 3px;
      padding: 7px;
      svg path {
        fill: $neutral-90;
      }
    }

    &-selected {
      padding: 8px;
      border: 1px solid $neutral-50;
      border-radius: 4px;
      background-color: $neutral-00;
      cursor: pointer;

      svg {
        width: 24px;
        height: 24px;

        path {
          fill: $neutral-90;
        }
      }
    }
  }
}
