@import '../../../scss/typography.scss';
@import '../../../scss/variables.scss';

.state {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  padding: 4px;
  height: fit-content;
  width: fit-content;
  gap: 4px;

  &.success {
    background-color: #cfeacc;
    .text {
      @include bodycopy-b3-semibold-caps;
      color: #2f7332;
      line-height: 100%;
    }
  }

  &.info {
    background-color: $semantic-blue-30;
    .text {
      @include bodycopy-b3-semibold-caps;
      color: $primary-blue-100;
      line-height: 100%;
    }
  }

  &.warning {
    background-color: $semantic-orange-30;
    .text {
      @include bodycopy-b3-semibold-caps;
      color: $semantic-orange-100;
      line-height: 100%;
    }
  }

  &.error {
    background-color: $semantic-red-30;
    .text {
      @include bodycopy-b3-semibold-caps;
      color: $semantic-red-100;
      line-height: 100%;
    }
  }
}
