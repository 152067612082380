// === COLORS ===

$neutral-00: #ffffff;
$neutral-20: #fafafa;
$neutral-30: #f3f2f0;
$neutral-40: #eae7e1;
$neutral-50: #d8d4cc;
$neutral-60: #afaca7;
$neutral-70: #84817b;
$neutral-80: #656462;
$neutral-90: #414141;
$neutral-100: #191e16;

$primary-blue-05: #f5f7f7;
$primary-blue-25: #e9eff0;
$primary-blue-50: #c5d4d5;
$primary-blue-75: #2c6567;
$primary-blue-100: #214c4e;

$semantic-blue-05: #f5f7f7;
$semantic-blue-30: #deeaeb;
$semantic-blue-75: #2c6567;
$semantic-blue-100: #2c6567;
$semantic-orange-05: #fcf7f2;
$semantic-orange-30: #f4e2d0;
$semantic-orange-75: #d9882b;
$semantic-orange-100: #c06b09;
$semantic-red-05: #faf2f2;
$semantic-red-30: #f8e1e1;
$semantic-red-75: #c22727;
$semantic-red-100: #9f1c1c;

$dark-secondary: #191e164d;

$accessibility: #18878c;

// === FONTS ===
// font families
$font-plus-jakarta: 'PlusJakartaFont';
$font-plus-jakarta-italic: 'PlusJakartaFontItalic';
$font-canela-thin: 'Canela-Thin';
$font-canela-thin-italic: 'Canela-Thin-Italic';

//font weights
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
//font sizes
$font-size-d3: 64px;
$font-size-h1: 48px;
$font-size-h2: 40px;
$font-size-h3: 32px;
$font-size-h4: 18px;
$font-size-b1: 16px;
$font-size-b2: 14px;
$font-size-b3: 12px;
