@import '../../scss/typography.scss';
@import '../../scss/variables.scss';

.homepage-container {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  .background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -10;
  }

  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -10;
  }

  .content-section {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 80px;

    .title-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      .homepage-title1 {
        @include header-h1-thin;
        color: $neutral-00;
        font-size: 72px;
        text-align: center;
      }

      .homepage-title2 {
        @include header-h1-thin-italic;
        color: $neutral-00;
        font-size: 72px;
        text-align: center;
      }
    }

    .background-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -10;
    }

    .content-section {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 80px;

      .title-wrapper {
        display: flex;
        flex-direction: column;

        .homepage-title1 {
          font-family: Canela Trial;
          font-size: 80px;
          font-weight: 100;
          line-height: 96px;
          text-align: center;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: $neutral-00;
        }

        .homepage-title2 {
          font-family: Canela Trial;
          font-size: 80px;
          font-style: italic;
          font-weight: 100;
          line-height: 96px;
          text-align: center;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: $neutral-00;
        }
      }
    }
  }
}
