@import '../../scss/typography.scss';
@import '../../scss/variables.scss';

.otp-verify-container {
  height: 100vh;

  .otp-verify-wrapper {
    display: flex;
    justify-content: flex-end;
    height: 100%;

    .content-wrapper {
      display: flex;
      width: 51%;
      justify-content: center;
      align-items: center;
      height: 100%;
      position: relative;

      .back-button-wrapper {
        position: absolute;
        top: 0;
        left: 65px;
        display: inline-flex;
        align-items: center;
        cursor: pointer;

        .back-icon {
          width: 16px;
          height: 16px;

          path {
            fill: $neutral-80;
          }
        }

        .back-button {
          @include bodycopy-b2-semibold;
          text-decoration: underline;
          color: $neutral-80;
          background-color: transparent;
          border: none;
          cursor: pointer;
        }
      }

      .content-section {
        width: 396px;
        display: flex;
        flex-direction: column;
        gap: 56px;

        .title-container {
          display: flex;
          flex-direction: column;
          gap: 40px;

          .main-title {
            @include header-h1-thin;
            color: $neutral-90;
          }

          .sub-title {
            @include bodycopy-b1-regular;
            color: $neutral-90;
          }
        }

        .input-section {
          display: flex;
          gap: 16px;

          .individual-input {
            height: 48px;
            width: 48px;
            border-radius: 4px;
            border: 1px solid $neutral-60;
            @include bodycopy-b2-regular;
            color: $neutral-90;
            text-align: center;
            font-size: 24px;

            &:focus-visible {
              outline: none;
            }
          }

          .individual-input-error {
            @extend .individual-input;
            border: 2px solid $semantic-red-100;
          }
        }

        .errorMessage {
          @include bodycopy-b3-regular;
          height: $font-size-b3;
          color: $semantic-red-100;
        }

        .cta-container {
          display: flex;
          flex-direction: column;

          .didnt_get_code {
            @include bodycopy-b2-regular;
            color: $neutral-80;
            margin-bottom: 16px;
            width: fit-content;
          }

          .new-code {
            @include bodycopy-b2-regular;
            color: $neutral-80;
            margin-bottom: 24px;
            width: fit-content;
          }

          .resend {
            @include bodycopy-b2-semibold;
            color: $neutral-80;
            cursor: pointer;
            margin-bottom: 24px;
            width: fit-content;
          }

          .change-service {
            @include bodycopy-b2-semibold;
            color: $neutral-80;
            cursor: pointer;
            width: fit-content;
          }
        }
      }
    }

    .background-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 49%;
      height: 100%;
      z-index: -1;
      object-fit: cover;
    }
  }
}
