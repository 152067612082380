@font-face {
  font-family: 'PlusJakartaFont';
  src: url('../assets/fonts/PlusJakartaSans-VariableFont_wght.ttf') format('truetype');
  font-weight: 200 800; /* Range from 200 to 800 */
  font-style: normal;
}

@font-face {
  font-family: 'PlusJakartaFontItalic';
  src: url('../assets/fonts/PlusJakartaSans-Italic-VariableFont_wght.ttf') format('truetype');
  font-weight: 200 800; /* Range from 200 to 800 */
  font-style: italic;
}

@font-face {
  font-family: 'Canela-Thin';
  src:
    url('../assets/fonts/Canela-Thin-Web.woff2') format('woff2'),
    url('../assets/fonts/Canela-Thin-Web.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Canela-Thin-Italic';
  src:
    url('../assets/fonts/Canela-ThinItalic-Web.woff2') format('woff2'),
    url('../assets/fonts/Canela-ThinItalic-Web.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}
