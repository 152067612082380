@import '../../../scss/typography.scss';
@import '../../../scss/variables.scss';

.tag {
  height: 24px;
  background-color: $primary-blue-25;
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
  @include bodycopy-b3-medium;
  color: $primary-blue-75;
  cursor: pointer;

  &:hover {
    border: 1px solid $primary-blue-100;
    color: $primary-blue-100;
    padding: 3px 7px 3px 7px;
  }
}

.tag-selected {
  height: 24px;
  background-color: $primary-blue-100;
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
  @include bodycopy-b3-medium;
  color: $neutral-00;
  cursor: pointer;

  &:hover {
    background-color: $primary-blue-100;
    color: $neutral-00;
  }
}

.tag-draft {
  height: 24px;
  background-color: $neutral-80;
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
  @include bodycopy-b3-medium;
  color: $neutral-00;
  border: 1px solid $neutral-00;
}

.tag-active {
  height: 24px;
  background-color: $primary-blue-100;
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
  @include bodycopy-b3-medium;
  color: $neutral-00;
  border: 1px solid $neutral-00;
}

.tag-inactive {
  height: 24px;
  background-color: $semantic-blue-30;
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
  @include bodycopy-b3-medium;
  color: $primary-blue-100;
  border: 1px solid $primary-blue-100;
}

.tag-submitted {
  height: 24px;
  background-color: $neutral-100;
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
  @include bodycopy-b3-medium;
  color: $neutral-00;
  border: 1px solid $neutral-00;
}
