@import '../../scss/typography.scss';
@import '../../scss/variables.scss';

.unsupported-device-container {
  .content-wrapper {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    justify-content: center;

    .logo {
      display: flex;
      padding-top: 24px;
      justify-content: center;
      z-index: 1;
    }

    .content-section {
      position: fixed;
      top: calc(50% + 24px);
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      width: 509px;
      height: auto;
      display: flex;
      flex-direction: column;
      padding: 40px 18.5px;
      gap: 32px;
      border-radius: 8px;
      background: rgba(250, 250, 250, 0.9);

      > div {
        width: 100%;
      }

      .title {
        @include header-h2-thin;
        color: $neutral-90;
      }

      .bodycopy {
        p,
        em {
          @include bodycopy-b1-regular;
          color: $neutral-90;
          margin-top: 0px;
          margin-bottom: 0px;

          br {
            content: '';
            display: block;
            height: 0px;
          }
        }

        strong {
          @include bodycopy-b1-semibold;
          color: $neutral-90;
          margin-top: 0px;
        }

        br {
          content: '';
          display: block;
          height: 8px;
        }
      }
    }
  }
  .background-image-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    .background-image {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    .overlay {
      background: rgba(25, 30, 22, 0.5);
      position: fixed;
      width: 100%;
      height: 100%;
    }
  }
}

.unsupported-device-container-small {
  .content-wrapper {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    justify-content: center;

    .logo {
      display: flex;
      padding: 24px;
      justify-content: center;
      z-index: 1;
    }

    .content-section {
      position: fixed;
      top: calc(50% + 24px);
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      width: 326px;
      height: auto;
      display: flex;
      flex-direction: column;
      padding: 40px 18.5px;
      margin-top: 24px;
      gap: 32px;
      border-radius: 8px;
      background: rgba(250, 250, 250, 0.9);

      > div {
        width: 100%;
      }

      .title {
        @include header-h3-canela;
        color: $neutral-90;
      }

      .bodycopy {
        p,
        em {
          @include bodycopy-b2-regular;
          color: $neutral-90;
          margin-top: 0px;
          margin-bottom: 0px;

          br {
            content: '';
            display: block;
            height: 0px;
          }
        }

        strong {
          @include bodycopy-b2-semibold;
          color: $neutral-90;
          margin-top: 0px;
        }

        br {
          content: '';
          display: block;
          height: 8px;
        }
      }
    }
  }
  .background-image-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    .background-image {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    .overlay {
      background: rgba(25, 30, 22, 0.5);
      position: fixed;
      width: 100%;
      height: 100%;
    }
  }
}
