@import '../../../scss/typography.scss';
@import '../../../scss/variables.scss';

.audio-player-container {
  .dropdown-content {
    .dropdown-box {
      @include bodycopy-b1-semibold;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
      padding: 8px 12px;
      color: $neutral-00;
      border-radius: 4px;
      height: 60px;
      cursor: pointer;

      &--open {
        background-color: rgba(255, 255, 255, 0.15);
      }
      &--muted {
        padding: 8px 4px 8px 10px;
      }
      button {
        cursor: pointer;
        background-color: transparent;
        border: none;
        padding: 0;
        display: flex;
      }

      .dropdown-box-border {
        height: 16px;
        width: 2px;
        background-color: rgba(175, 172, 167, 1);
      }
      svg.audio-icon {
        width: 30px;
        height: 32px;
        path {
          fill: $neutral-00;
        }
      }
    }
  }
}
