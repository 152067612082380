@import '../../../../scss/variables.scss';

.icon-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.icon-button_small {
  // background-color: transparent;
  width: 20px;
  height: 20px;
  // padding: 2px;
  // border: 2px solid transparent;
  border-radius: 2px;
  cursor: pointer;

  > svg {
    width: 16px;
    height: 16px;

    path {
      fill: $neutral-80;
    }
  }

  &:hover {
    background-color: $neutral-20;

    svg path {
      fill: $neutral-90;
    }
  }
}

.icon-button_default {
  @extend .icon-button;
  border: 1px solid $primary-blue-100;
  padding: 8px;
  border-radius: 4px;

  svg path {
    fill: $primary-blue-100;
  }

  &:hover {
    background-color: $primary-blue-05;
  }
}

.icon-button_imageBg {
  @extend .icon-button;
  border-radius: 4px;
  border: 1px solid $neutral-00;
  background-color: rgba(25, 30, 22, 0.3);
  backdrop-filter: blur(10px);

  svg path {
    fill: $neutral-00;
  }

  &:active {
    background-color: $neutral-00;
    border: 1px solid $neutral-50;

    &:hover {
      background-color: $neutral-00;
      border: 1px solid $neutral-50;
      box-shadow: none;
    }

    svg path {
      fill: $neutral-90;
    }
  }

  &:hover {
    border: 1px solid $neutral-40;
    box-shadow:
      2px 2px 15px rgba(146, 141, 133, 0.15),
      1px 1px 10px rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(10px);

    svg path {
      fill: $neutral-90;
    }
  }

  &:focus {
    outline: 2px solid $accessibility;
  }
}
