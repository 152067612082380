@import './fonts.scss';
@import './variables.scss';

// display
@mixin display-d3 {
  font-family: $font-canela-thin;
  font-weight: 100;
  font-size: $font-size-d3;
  line-height: 120%;
  font-variant-ligatures: none;
}

//header
@mixin header-h1-thin {
  font-family: $font-canela-thin;
  font-weight: 100;
  font-size: $font-size-h1;
  line-height: 120%;
  font-variant-ligatures: none;
}

@mixin header-h1-thin-italic {
  font-family: $font-canela-thin-italic;
  font-weight: 100;
  font-size: $font-size-h1;
  line-height: 120%;
  font-variant-ligatures: none;
}

@mixin header-h1-thin-caps {
  font-family: $font-canela-thin;
  font-weight: 100;
  font-size: $font-size-h1;
  line-height: 140%;
  text-transform: uppercase;
  font-variant-ligatures: none;
}

@mixin header-h2-thin {
  font-family: $font-canela-thin;
  font-weight: 100;
  font-size: $font-size-h2;
  line-height: 120%;
  font-variant-ligatures: none;
}

@mixin header-h3-canela {
  font-family: $font-canela-thin;
  font-weight: 500;
  font-size: $font-size-h3;
  line-height: 120%;
  font-variant-ligatures: none;
}

@mixin header-h3-medium {
  font-family: $font-plus-jakarta;
  font-weight: 500;
  font-size: $font-size-h4;
  line-height: 120%;
  font-variant-ligatures: none;
}

@mixin header-h4-semibold {
  font-family: $font-plus-jakarta;
  font-weight: 600;
  size: $font-size-h4;
  line-height: 22.68px;
  font-variant-ligatures: none;
}

@mixin header-h1-canela-caps {
  font-family: 'Canela Trial';
  font-style: italic;
  font-weight: 100;
  font-size: 48px;
  line-height: 67px;
  font-variant-ligatures: none;
}

// body B1
@mixin bodycopy-b1-regular {
  font-family: $font-plus-jakarta;
  font-weight: $font-weight-regular;
  font-size: $font-size-b1;
  line-height: 160%;
  font-variant-ligatures: none;
}
@mixin bodycopy-b1-medium {
  font-family: $font-plus-jakarta;
  font-weight: $font-weight-medium;
  font-size: $font-size-b1;
  line-height: 160%;
  font-variant-ligatures: none;
}
@mixin bodycopy-b1-semibold {
  font-family: $font-plus-jakarta;
  font-weight: $font-weight-semibold;
  font-size: $font-size-b1;
  line-height: 160%;
  font-variant-ligatures: none;
}
@mixin bodycopy-b1-semibold-caps {
  font-family: $font-plus-jakarta;
  font-weight: $font-weight-semibold;
  font-size: $font-size-b1;
  text-transform: uppercase;
  line-height: 160%;
  font-variant-ligatures: none;
}

//body B2
@mixin bodycopy-b2-regular {
  font-family: $font-plus-jakarta;
  font-weight: $font-weight-regular;
  font-size: $font-size-b2;
  line-height: 160%;
  font-variant-ligatures: none;
}
@mixin bodycopy-b2-medium {
  font-family: $font-plus-jakarta;
  font-weight: $font-weight-medium;
  font-size: $font-size-b2;
  line-height: 160%;
  font-variant-ligatures: none;
}
@mixin bodycopy-b2-semibold {
  font-family: $font-plus-jakarta;
  font-weight: $font-weight-semibold;
  font-size: $font-size-b2;
  line-height: 160%;
  font-variant-ligatures: none;
}
@mixin bodycopy-b2-semibold-caps {
  font-family: $font-plus-jakarta;
  font-weight: $font-weight-semibold;
  font-size: $font-size-b2;
  text-transform: uppercase;
  line-height: 160%;
  font-variant-ligatures: none;
}

//body B3
@mixin bodycopy-b3-regular {
  font-family: $font-plus-jakarta;
  font-weight: $font-weight-regular;
  font-size: $font-size-b3;
  line-height: 150%;
  font-variant-ligatures: none;
}
@mixin bodycopy-b3-medium {
  font-family: $font-plus-jakarta;
  font-weight: $font-weight-medium;
  font-size: $font-size-b3;
  line-height: 150%;
  font-variant-ligatures: none;
}
@mixin bodycopy-b3-semibold {
  font-family: $font-plus-jakarta;
  font-weight: $font-weight-semibold;
  font-size: $font-size-b3;
  line-height: 150%;
  font-variant-ligatures: none;
}
@mixin bodycopy-b3-semibold-caps {
  font-family: $font-plus-jakarta;
  font-weight: $font-weight-semibold;
  font-size: $font-size-b3;
  text-transform: uppercase;
  line-height: 160%;
  font-variant-ligatures: none;
}
