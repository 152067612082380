@import '../../scss/typography.scss';
@import '../../scss/variables.scss';

.client-data-container {
  height: 100vh;

  .client-data-wrapper {
    display: flex;
    justify-content: flex-end;
    height: 100%;

    .content-wrapper {
      display: flex;
      width: 40%;
      justify-content: center;
      align-items: center;
      height: 100%;
      padding: 88px 0px 0px 0px;
      position: relative;

      .client-data {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: auto;
        gap: 56px;

        .back-button {
          display: inline-flex;
          align-items: center;
          cursor: pointer;
        }

        &::-webkit-scrollbar {
          display: none;
        }

        .client-data-header {
          display: flex;
          flex-direction: column;
          gap: 40px;
          width: 473px;

          &-title {
            @include header-h1-thin-caps;
            color: $neutral-90;
          }
          &-description {
            @include bodycopy-b1-regular;
            color: $neutral-90;
          }
        }

        .client-data-body {
          display: flex;
          flex-direction: column;
          gap: 15px;
          width: 473px;

          .dropdown {
            display: flex;
            flex-direction: row;
            flex: 1;
            gap: 16px;
          }

          .brokerage {
            display: flex;
            flex-direction: column;
            gap: 8px;

            &-title {
              padding-bottom: 8px;
              @include bodycopy-b3-regular;
              color: $neutral-80;
            }

            &-list {
              display: flex;
              flex-direction: row;
              gap: 24px;

              .radio-item,
              .radio-item-error {
                padding-bottom: 0px;
              }
            }
            &-error-message {
              @include bodycopy-b3-regular;
              height: $font-size-b3;
              color: $semantic-red-100;
            }
          }
        }

        .client-data-footer {
          display: flex;
          flex-direction: column;
          gap: 32px;
          width: 473px;
          &-list {
            display: flex;
            flex-direction: column;
            gap: 16px;
          }
          &-button {
            align-self: flex-end;
          }
        }
      }
    }
    &-background-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 60%;
      height: 100%;
      z-index: -1;
      object-fit: cover;
    }
  }
  .checkbox-item {
    padding-bottom: 0px;
  }

  p {
    color: $neutral-90;
    font-size: $font-size-b3;
    font-family: $font-plus-jakarta;
  }
}
