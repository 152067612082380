.slideshow {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
}

.left-button {
  position: absolute;
  top: 50%;
  left: 2.5%;
  z-index: 1001;
}

.right-button {
  position: absolute;
  top: 50%;
  right: 2.5%;
  z-index: 1001;
}
