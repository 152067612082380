@import '../../scss/variables.scss';
@import '../../scss/typography.scss';

.admin-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100vh;

  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #191e16;
    opacity: 0.8;
    z-index: -9;
    > img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -10;
      filter: blur(4px);
    }
  }

  .uploader {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    width: 650px;
    background-color: #fff;
    opacity: 0.9;
    padding: 40px;
    gap: 20px;

    .title {
      @include header-h1-thin;
    }
    .text {
      @include bodycopy-b1-regular;
    }
    .textshort {
      @include bodycopy-b3-regular;
    }

    .dropzone {
      background: #fafafa;
      border: 2px dashed #cccccc;
      padding: 20px;
      text-align: center;
      border-radius: 5px;
      width: 570px;

      cursor: pointer;
      &.active {
        background: #f0f8ff;
      }

      .choose {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        .label {
          @include bodycopy-b3-regular;
          line-height: 100%;
        }
      }
    }

    .fileName {
      @include bodycopy-b2-semibold;
    }

    .selected-file {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .name {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        svg {
          width: 16px;
          height: 16px;
        }
      }
      .upload-button {
        display: flex;
        width: 100%; // NEW:this will help to button to take full-width
        justify-content: flex-start;
      }
    }
  }
}
