@import '../../../../scss/typography.scss';
@import '../../../../scss/variables.scss';

.phone-input-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  .phone-input-label-wrapper {
    display: flex;
    flex-direction: row;
    gap: 8px;

    .country-code-label {
      width: 30%;
      @include bodycopy-b3-regular;
      color: $neutral-80;
    }
    .phone-label {
      width: 88%;
      @include bodycopy-b3-regular;
      color: $neutral-80;
    }
    .optional-label {
      padding-right: 2px;
      @include bodycopy-b3-regular;
      color: $neutral-80;
      font-style: italic;
    }
  }

  .errorMessage {
    @include bodycopy-b3-regular;
    height: $font-size-b3;
    color: $semantic-red-100;
  }
}

.phone-input-wrapper {
  display: inline;
  flex-direction: row;
  gap: 8px;
  width: 100%;
  height: 51px;

  .phone-input-clear-icon {
    position: relative;
    bottom: 68%;
    left: 91%;
    background: none;
    border: none;
    cursor: pointer;

    svg {
      width: 16px;
      height: 16px;
      path {
        fill: $neutral-80;
      }
    }
  }

  .react-international-phone-input-container {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }

  .react-international-phone-country-selector {
    width: 30%;
    height: 51px;

    &-button {
      width: 115.92px;
      height: 51px;
      border: 1px solid $neutral-60;
      background-color: $neutral-00;
      border-radius: 4px;
      padding: 14px 16px 14px 16px;

      &:hover {
        border-color: $neutral-80;
      }

      &.custom-phone-button.error {
        border-width: 2px;
        padding: 13px 15px 13px 15px;
        border-color: $semantic-red-75;

        &:hover {
          border-color: $semantic-red-75;
        }
      }

      &--active {
        width: 115.92px;
        padding: 13px 15px 13px 15px;
        border-width: 2px;
        border-color: $accessibility;
        outline: none;

        &:hover {
          border-color: $accessibility;
        }
      }

      &__button-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        img {
          width: 25px;
          height: 20px;
        }
      }

      &__dropdown-arrow {
        content: url('../../../../assets/svg/icons/navigation/chevron-down.svg');
        width: 16px;
        height: 16px;
        color: $neutral-80;

        &--active {
          transform: rotate(180deg);
        }
      }
    }

    &-dropdown {
      width: 342%;
      height: 400px;
      overflow: auto;
      border: 1px solid $neutral-50;
      border-radius: 4px;
      background-color: $neutral-00;
      position: relative;
      z-index: 9999;
      list-style-type: none;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-inline-start: 0px;
      margin-block-start: 4px;
      margin-block-end: 0px;
      box-shadow: 2px 2px 15px 0px #afaca733;

      &::-webkit-scrollbar {
        display: none;
      }

      &:focus-visible {
        outline: none;
      }

      &__list-item {
        position: relative;
        display: flex;
        height: 40px;
        padding: 12px 16px;
        align-items: center;
        gap: 8px;

        img {
          width: 25px;
          height: 20px;
        }

        span {
          @include bodycopy-b2-regular;
          color: $neutral-90;
        }

        &:hover {
          background-color: $primary-blue-05;
        }

        &--selected {
          background-color: $primary-blue-25;

          &:hover {
            background-color: $primary-blue-25;
          }

          &::after {
            content: '';
            background-image: url('../../../../assets/svg/icons/semantic/check.svg');
            background-size: contain;
            background-position: right;
            width: 16px;
            height: 16px;
            fill: $neutral-80;
            position: absolute;
            right: 16px;
          }
        }
      }
    }
  }

  .custom-phone-input {
    width: 100%;
    border: 1px solid $neutral-60;
    border-radius: 4px;
    padding: 14px 48px 14px 16px;
    @include bodycopy-b1-regular;
    color: $neutral-90;
    line-height: 100%;

    &.error {
      border-width: 2px;
      padding: 13px 47px 13px 15px;
      border-color: $semantic-red-75;

      &:hover {
        border-color: $semantic-red-75;
      }

      &:focus-within {
        border-color: $semantic-red-75;
        &:hover {
          border-color: $semantic-red-75;
        }
      }
    }

    &:hover {
      border-color: $neutral-80;
    }

    &:focus-within {
      padding: 13px 47px 13px 15px;
      border-width: 2px;
      border-color: $accessibility;
      outline: none;

      &:hover {
        border-color: $accessibility;
      }
    }

    &::selection {
      background-color: $primary-blue-25;
    }
  }
}
