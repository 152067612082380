@import '../../../../scss/variables.scss';
@import '../../../../scss/typography.scss';

.cell {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 80px;
  height: 40px;
  background-color: $neutral-00;
  padding: 8px 24px 8px 24px;
  border-radius: 4px;
  border: 1px solid $neutral-50;

  cursor: pointer;

  &:hover {
    border: 1px solid $neutral-40;
    box-shadow: 2px 2px 15px 0px #afaca733;
  }

  &.selected {
    background-color: $primary-blue-05;
    border-radius: 5px;
    border: 2px solid $primary-blue-100;

    &.disabled {
      background-color: $neutral-30;
      border-radius: 5px;
      outline: 2px solid $neutral-80;
      border-color: transparent;
      cursor: not-allowed;

      &:hover {
        box-shadow: none;
      }
    }
  }

  &.disabled {
    background-color: $neutral-20;
    border-radius: 5px;
    outline: 1px solid $neutral-40;
    border-color: transparent; // Hide the inner border
    cursor: not-allowed;

    &:hover {
      box-shadow: none;
    }
  }
}

.cell-text {
  @include bodycopy-b1-regular;
  color: $neutral-90;
  display: inline-flex;
  align-items: center;
  align-content: center;
  text-align: center;

  &.selected {
    @include bodycopy-b1-semibold;
    color: $neutral-90;

    &.disabled {
      @include bodycopy-b1-semibold;
      color: $neutral-90;
    }
  }

  &.disabled {
    @include bodycopy-b1-regular;
    color: $neutral-80;
  }
}
