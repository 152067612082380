@import '../../../../../scss/typography.scss';
@import '../../../../../scss/variables.scss';

.partners-modal-swiper {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex !important;
  flex-direction: column;
}

.partners-modal-swiper-slide {
  display: flex !important;
  flex-direction: row;
  align-self: center;
  gap: 40px;
  background: $neutral-20;
  border-radius: 8px;
  border: 2px solid $neutral-40;
  width: 1312px !important;
  height: 748px !important;
  box-shadow: none;
  padding: 40px 80px;
  margin-right: 0 !important;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;

    &-track {
      background: $primary-blue-50;
      border-radius: 2px;
      border: 1px solid $neutral-20;
    }

    &-thumb {
      border-radius: 2px;
      background: $primary-blue-100;
    }
  }
}

.services-partner {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  height: 100%;

  .services-partner-content {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .partners-modal-text-header {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      height: 24px;
      z-index: 99999;
      background-color: transparent;

      .partners-modal-close {
        right: 40px;
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }

    .services-partner-content-name {
      @include header-h2-thin;
      color: $neutral-100;
      line-height: 100%;
    }

    .services-partner-content-details {
      display: flex;
      flex-direction: row;
      gap: 32px;

      > img {
        width: 185.69px;
        height: 200px;
        border-radius: 4px;
      }

      .services-partner-content-details-service {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        gap: 32px;

        .services-partner-content-details-service-title {
          @include header-h2-thin;
          font-size: 32px;
          color: $neutral-100;
          line-height: 100%;
        }

        .services-partner-content-details-service-description {
          @include bodycopy-b1-regular;
          color: $neutral-90;
        }
      }
    }
  }

  .services-partner-quote {
    display: flex;

    span {
      @include display-d3;
      text-align: center;
      color: $neutral-90;
    }
  }

  .services-partner-projects {
    display: flex;
    flex-direction: column;
    gap: 24px;

    span {
      @include header-h2-thin;
      color: $neutral-100;
      line-height: 100%;
    }

    .services-partner-projects-images {
      display: inline-flex;
      flex-direction: row;
      gap: 20px;

      img {
        width: 213.75px;
        height: 250px;
        border-radius: 4px;
      }
    }
  }
}
