@import '../../scss/typography.scss';
@import '../../scss/variables.scss';

.client-container {
  .client-wrapper {
    align-items: center;
    margin: auto;

    .content-section {
      position: fixed;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      width: 476px;
      max-width: calc(100vw - 40px);
      height: 389px;
      min-height: fit-content;
      display: flex;
      flex-direction: column;
      padding: 40px;
      gap: 56px;
      border-radius: 8px;
      background: rgba(250, 250, 250, 0.9);

      > div {
        width: 100%;
      }

      .main-title {
        @include header-h1-thin;
        color: $neutral-90;
      }

      .button-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }
    }

    .background-image-container {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      .background-image {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
      .overlay {
        background: rgba(25, 30, 22, 0.5);
        position: fixed;
        width: 100%;
        height: 100%;
      }
    }
  }
}
