@import '../../scss/typography.scss';
@import '../../scss/variables.scss';

.dashboard-container {
  min-height: 100vh;
  position: relative;

  .content-container {
    display: flex;
    background-color: $neutral-20;
    width: calc(100vw - 480px);
    justify-content: center;
    transition: width 0.3s ease-in-out;

    &.sidePanelIsCollapsed {
      width: calc(100vw - 48px);
    }

    .projects-wrapper {
      display: flex;
      flex-direction: column;
      padding: 120px 40px 0px 40px;
      min-height: 100vh;
      height: fit-content;

      .titleAndContentDiv {
        width: 1180px;

        .title-section {
          display: flex;
          flex-direction: column;
          gap: 24px;
          margin-bottom: 40px;

          .title {
            @include header-h3-canela;
            color: $neutral-90;
          }

          .subtitle {
            @include bodycopy-b1-regular;
            color: $neutral-90;
          }
        }

        .main-content-wrapper {
          display: flex;
          flex-direction: column;
          width: 100%;
          transition: width 0.3s ease-in-out;

          .filter-section {
            display: flex;
            gap: 24px;
            justify-content: space-between;
            margin-bottom: 16px;
            align-items: center;

            .filter-wrapper {
              display: flex;
              gap: 2px;
              align-items: center;

              .tags-wrapper {
                display: flex;
                gap: 8px;
              }
            }

            .sort {
              @include bodycopy-b3-regular;
              color: $neutral-80;
            }

            .filter {
              @include bodycopy-b3-regular;
              color: $neutral-80;

              &.tag {
                background-color: $primary-blue-25;
                color: $primary-blue-100;
                border-radius: 4px;
                padding: 4px 8px 4px 8px;
                display: flex;
                align-items: center;
                gap: 8px;

                svg {
                  width: 16px;
                  height: 16px;
                  cursor: pointer;
                }

                path {
                  fill: $primary-blue-100;
                }
              }
            }
          }

          .projects-section {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 24px;
            transition: width 0.3s ease-in-out;
            scrollbar-width: none;

            .projects-section::-webkit-scrollbar {
              display: none;
            }

            .project-card {
              width: 277px;
              min-height: 367px;
              height: fit-content;
              background: $neutral-00;
              padding: 16px;
              text-align: center;
              border: 1px solid $neutral-40;
              border-radius: 4px;
              overflow: hidden;
              display: flex;
              flex-direction: column;
              gap: 24px;

              .project-info {
                display: flex;
                flex-direction: column;
                gap: 12px;
                margin-top: 20px;
                cursor: pointer;

                .project-title-wrapper {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                }

                .project-title-wrapper {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                }

                .project-name {
                  text-align: left;
                  @include bodycopy-b1-semibold-caps;
                  color: $neutral-90;
                }

                .project-info-key {
                  @include bodycopy-b3-regular;
                  color: $neutral-90;
                  line-height: 100%;
                }

                .project-info-value {
                  @include bodycopy-b3-regular;
                  color: $neutral-80;
                  line-height: 100%;
                }
              }

              .image-wrapper {
                height: 139px;
                border-radius: 4px;
                overflow: hidden;
                background-color: $neutral-40;
                position: relative;

                .tag-wrapper {
                  position: absolute;
                  top: 8px;
                  right: 8px;
                  z-index: 2;
                  pointer-events: none;

                  &.pointer {
                    cursor: pointer;
                    pointer-events: all;
                  }
                }

                .project-image {
                  width: 100%;
                  height: 100%;
                  object-position: center;
                  object-fit: none;
                }
              }
            }

            .new-design-card {
              @extend .project-card;
              height: auto;
              min-height: 367px;

              .new-design-content {
                display: flex;
                flex-direction: column;
                align-items: center;
                background-color: $neutral-30;
                justify-content: center;
                gap: 40px;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}

.side-panel-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  background-color: $neutral-00;
  transition: width 0.3s ease-in-out;
  z-index: 3;
  height: 100vh;
  overflow-y: auto;
  scrollbar-width: none;

  .side-panel-wrapper::-webkit-scrollbar {
    display: none;
  }

  &.collapsed {
    width: 48px;
    padding: 24px 8px 24px 8px;
  }

  &.expanded {
    width: 480px;
    padding: 56px 40px 24px 40px;
    flex-direction: column;

    .clients-section {
      margin-top: 64px;

      .clients-title {
        @include header-h3-medium;
        color: $neutral-90;
      }

      .clients-cards {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-top: 24px;
        cursor: pointer;

        .client-card {
          width: 100%;
          height: 137px;
          background-color: $primary-blue-05;
          display: flex;
          flex-direction: column;
          padding: 16px;
          overflow: hidden;
          justify-content: space-evenly;
          border-radius: 4px;

          &.selected {
            background-color: $primary-blue-25;
            border: 2px solid $primary-blue-100;
            padding: 14px;
          }

          .client-name {
            @include bodycopy-b1-regular;
            color: $neutral-90;
          }

          .client-info-key {
            @include bodycopy-b3-regular;
            color: $neutral-90;
          }

          .client-info-value {
            @include bodycopy-b3-regular;
            color: $neutral-80;
          }
        }
      }
    }
  }

  .collapser-div {
    position: absolute;
    top: 24px;
    right: 12px;

    svg {
      width: 16px;
      height: 16px;

      path {
        fill: $neutral-80;
      }
    }
  }

  .footer-div {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    // height: 100%;
    align-items: flex-end;
  }
}

.options-menu {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-height: 300px;
  overflow: auto;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }

  padding: 8px 0px 8px 0px;
  gap: 4px;

  background-color: $neutral-00;

  margin-top: 4px;
  border: 1px solid $neutral-50;
  border-radius: 4px;
  box-shadow: 2px 2px 15px 0px #afaca733;
}

.filter-header {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99999;
  background-color: transparent;

  padding: 16px 14px 0px 14px;
}

.filter-content-div {
  .filter-title {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding-bottom: 53px;

    .headline {
      @include header-h1-thin;
    }

    .description {
      @include bodycopy-b1-regular;
    }
  }

  .inputs-wrapper {
    display: flex;
    flex-direction: column;
    gap: 44px;
  }

  .status-filter-div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .status-title {
      @include bodycopy-b1-semibold-caps;
      color: $neutral-90;
      margin-bottom: 24px;
      margin-top: 36px;
    }

    .clear-selection {
      @include bodycopy-b3-medium;
    }
  }
}
