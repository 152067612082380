@import '../../scss/typography.scss';

.questions-container {
  display: flex;
  flex-direction: column;
  gap: 160px;

  // .question-wrapper:nth-of-type(n + 2) {
  //   margin-top: 160px;
  // }

  .question-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .top-section {
      display: flex;
      flex-direction: column;
      gap: 32px;
      padding-bottom: 56px;

      .headline {
        @include header-h1-thin;
        color: $neutral-100;
      }
      .description {
        @include bodycopy-b1-regular;
        color: $neutral-90;
      }
    }

    .filters {
      display: flex;
      width: 100%; // NEW:this will help to button to take full-width
      justify-content: flex-end;
    }

    .answers {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%; // NEW:this will help to button to take full-width
    }

    .resetMacroplots {
      display: flex;
      width: 100%; // NEW:this will help to button to take full-width
      justify-content: flex-start;
      margin-top: 24px;
    }

    .showMorePlots {
      display: flex;
      width: 100%; // NEW:this will help to button to take full-width
      justify-content: center;
      margin-top: 24px;
    }

    .inline-options {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(min(100px, 100%), 1fr));
      gap: 8px;
    }
  }
}
