@import '../scss/variables.scss';

.container {
  display: grid;
  grid-template-columns: 1fr 33%;
  height: 100vh; /* Full viewport height */
  position: relative; /* Needed for absolute positioning of the left div */
  overflow: hidden; /* Prevents overall scrolling on the container */

  &.expanded {
    grid-template-columns: 1fr 48px;
  }
}

.top {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2000;
  background-color: transparent;

  padding: 16px 14px 0px 14px;

  &.expanded {
    height: 100vh;
    background-color: $neutral-00;
    box-shadow: -2px 0px 5px 0px #00000026;
  }
}

.image-container {
  // width: 100%;
  // height: 100%;
  // overflow: hidden; /* Ensure the image doesn't overflow */
  // position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Prevents scrolling on the left */
  position: relative;

  // width: 100%; /* The container can be any width, usually 100% for responsive layouts */
  // height: auto; /* Allows height to adjust based on content or viewport */
  // position: relative;

  img {
    width: 100%; /* Image will take the full width of the parent container */
    height: 100%; /* Image will take the full height of the parent container */
    object-fit: cover; /* Ensures the image covers the container while maintaining aspect ratio */
    display: block;
  }

  .slideshow {
    width: 100%; /* Image will take the full width of the parent container */
    height: 100%; /* Image will take the full height of the parent container */
    object-fit: cover; /* Ensures the image covers the container while maintaining aspect ratio */
    display: block;
  }

  // img {
  //   // max-width: 100%;
  //   // max-height: 100%;
  //   object-fit: cover; /* Ensures the image fits nicely inside the container */
  // }
}

// .full-image {
//   width: 100%;
//   height: 100%;
//   object-fit: cover; /* Ensure the image covers the div without stretching */
//   position: absolute;
//   top: 0;
//   left: 0;
// }

.side-panel {
  background-color: $neutral-00; /* Darker grey for visibility */
  overflow-y: auto; /* Allows the right div to scroll vertically */
  // height: 100%;
  min-height: 100vh; /* Full viewport height */
  // overflow: scroll;
  display: grid;
  grid-template: auto 1fr auto / 1fr;
  grid-auto-flow: column;
  z-index: 5;
  position: relative;
  box-shadow: -2px 0px 5px 0px #00000026;

  &::-webkit-scrollbar {
    display: none;
  }
}
