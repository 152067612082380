@import '../../../scss/variables.scss';
@import '../../../scss/typography.scss';

.salesManagerLogin {
  display: flex;

  padding: 200px 0px 0px 0px;

  .authentication {
    display: flex;
    flex-direction: column;
    width: 601px;
    max-width: 40%;

    padding: 0px 0px 0px 164px;

    .back-button {
      cursor: pointer;
      padding-bottom: 56.5px;
    }

    &-title {
      @include header-h1-thin;
      color: $neutral-100;
      padding-bottom: 80px;
    }

    &-form {
      display: flex;
      flex-direction: column;
      gap: 15px;

      .input-section {
        display: flex;
        flex-direction: column;
        gap: 8px;

        &_label {
          @include bodycopy-b3-regular;
          color: $neutral-80;
        }

        &_input-wrapper {
          position: relative;
          width: 420px;
        }
      }
    }

    .authentication-form {
      .password-message {
        display: inline-flex;
        gap: 8px;

        .icon {
          padding-top: 2px;
          > svg {
            width: 16px;
            height: 16px;
            path {
              fill: $neutral-80;
            }
          }
        }

        span {
          @include bodycopy-b3-regular;
          color: $neutral-80;

          a {
            @include bodycopy-b3-regular;
            color: $neutral-80;
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }

    .button-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: auto; // Ensures it only takes the space it needs
      margin-top: 48px;
    }
  }

  .image-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    width: cover;
    max-width: 60%;
    height: 100%;
    z-index: -1;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
