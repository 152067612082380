@import '../../../scss/typography.scss';
@import '../../../scss/variables.scss';

.header-container {
  position: absolute;
  display: flex;
  justify-content: space-between;
  z-index: 1;
  padding: 24px 40px 24px 40px;
  width: 100%;
  align-items: center;
  height: 88px;
  transition: width 0.3s ease-in-out;

  &.isCollapsed {
    width: calc(100vw - 480px);
  }

  .login-section {
    display: flex;
    justify-content: space-between;
    width: fit-content;
    height: 100%;
    gap: 16px;
    .audio-button {
      background: none;
      border: none;
    }
    .user-wrapper {
      display: flex;
      align-items: center;
      border-radius: 4px;
      padding: 0px 4px;

      &:hover {
        background-color: #f3f2f0b2;
        .user-icon {
          path {
            fill: $neutral-90;
          }
        }
      }

      .user-icon {
        width: 40px;
        cursor: pointer;
        display: flex;
        border-radius: 4px;

        path {
          fill: $neutral-00;
        }
      }
    }
    .language-wrapper {
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      gap: 8px;
      border-radius: 4px;
      padding: 0px 8px;

      &:hover {
        background-color: #f3f2f0b2;
        .globe-icon {
          path {
            fill: $neutral-90;
          }
        }
        .language-label {
          color: $neutral-90;
        }
      }

      .globe-icon {
        text-align: center;
        path {
          fill: $neutral-00;
        }
      }
      .language-label {
        @include bodycopy-b1-semibold;
        text-align: center;
        color: $neutral-00;
      }
    }
  }
  .login-section-dark {
    @extend .login-section;
    .user-wrapper {
      display: flex;
      align-items: center;
      .user-icon {
        width: 40px;
        height: 24px;
        cursor: pointer;
        display: flex;
        path {
          fill: $neutral-80;
        }
      }
    }
    .language-wrapper {
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      gap: 8px;
      .globe-icon {
        text-align: center;
        path {
          fill: $neutral-80;
        }
      }
      .language-label {
        @include bodycopy-b1-semibold;
        text-align: center;
        color: $neutral-80;
      }
    }
  }
}

.menu-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;

  padding: 8px 0px 8px 0px;
  border-radius: 4px;

  background-color: $neutral-00;
  border: 1px solid $neutral-40;
  box-shadow: 6px 6px 25px 0px #928d8533;

  margin-bottom: 8px;
}

.language-menu-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 300px;
  overflow: hidden;
  position: relative;

  padding: 8px 0px 8px 0px;
  gap: 4px;

  background-color: $neutral-00;

  margin-top: 4px;
  border: 1px solid $neutral-50;
  border-radius: 4px;
  box-shadow: 2px 2px 15px 0px #afaca733;
}
