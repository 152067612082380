@import '../../../scss/typography.scss';
@import '../../../scss/variables.scss';

.accordion__container {
  @include bodycopy-b2-medium;
  color: $neutral-90;
  width: 100%;

  &-open {
    display: flex;
    flex-direction: column;
    width: 100%;

    svg {
      width: 16px;
      height: 16px;
      color: $neutral-90;
      transition: transform 0.3s ease;
    }
  }

  &-header-line {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &-header-close {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    svg {
      width: 16px;
      height: 16px;
      color: $neutral-90;
      transition: transform 0.3s ease;
    }
  }

  &-header-open {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding-bottom: 16px;
  }

  &-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &-item {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

.item-label {
  @include bodycopy-b3-regular;
  color: $neutral-90;
  padding-left: 4px;
}

.item-value {
  @include bodycopy-b3-regular;
  color: $neutral-80;
}

.item-title {
  @include bodycopy-b3-medium;
  color: $neutral-90;
}
