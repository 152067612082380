@import '../../../../scss/typography.scss';
@import '../../../../scss/variables.scss';

.link-button {
  display: inline-flex;
  align-items: center;
  gap: 6px;

  cursor: pointer;

  svg {
    height: 16px;
    width: 16px;
  }

  > svg path {
    fill: $neutral-80;
  }

  &:active,
  &:hover {
    > svg path {
      fill: $neutral-90;
    }
  }

  &:focus-visible {
    outline-offset: 2px;
    outline: 2px solid $accessibility;
  }

  &_default.text {
    @include bodycopy-b2-semibold;
    color: $neutral-80;
    line-height: 100%;
    text-decoration: underline;
    text-underline-offset: 2px;

    &:active,
    &:hover {
      color: $neutral-90;
    }
  }

  &_extra-small.text {
    @include bodycopy-b3-semibold;
    color: $neutral-80;
    line-height: 100%;
    text-decoration: underline;
    text-underline-offset: 2px;

    &:active,
    &:hover {
      color: $neutral-90;
    }
  }
}
