@import '../../../scss/variables.scss';
@import '../../../scss/typography.scss';

.information {
  display: grid;
  grid-template: auto auto / 1fr;
  gap: 12px;
  background-color: $primary-blue-05;
  border-radius: 4px;
  padding: 12px 16px 16px 16px;
  cursor: pointer;

  &:hover {
    background-color: $primary-blue-25;
  }
}

.information_title {
  @include header-h3-medium;
  color: $neutral-90;
}

.information_description {
  @include bodycopy-b3-regular;
  color: $neutral-90;
}

.information-row {
  display: flex;
  justify-content: space-between;

  svg path {
    fill: $neutral-90;
  }
}
