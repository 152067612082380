@import '../../../scss/typography.scss';
@import '../../../scss/variables.scss';

.inline-message.info {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  > svg {
    width: 16px;
    height: 16px;
    path {
      fill: $neutral-80;
    }
  }

  p {
    @include bodycopy-b3-medium;
    color: $neutral-80;
  }
}

.inline-message.error {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  > svg {
    width: 16px;
    height: 16px;
    path {
      fill: $semantic-red-100;
    }
  }

  p {
    @include bodycopy-b3-medium;
    color: $semantic-red-100;
  }
}
