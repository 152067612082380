@import '../../../../scss/typography.scss';
@import '../../../../scss/variables.scss';

.additional-services-card {
  display: flex;
  flex-direction: column;
  background-color: $primary-blue-25;
  padding: 24px 16px 16px 16px;
  gap: 24px;

  &-title {
    @include bodycopy-b1-semibold-caps;
    color: $neutral-90;
    line-height: 100%;
  }
  &-description {
    @include bodycopy-b2-regular;
    color: $neutral-90;
  }
}
