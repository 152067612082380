@import '../../../scss/variables.scss';
@import '../../../scss/typography.scss';

.form-footer {
  display: flex;
  flex: 1 0 auto;
  justify-content: space-between;
  padding-top: 64px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 48px;
}

.more-menu {
  display: flex;
  flex-direction: column;
  flex: 1;

  padding: 8px 0px 8px 0px;
  border-radius: 4px;

  background-color: $neutral-00;
  border: 1px solid $neutral-40;
  box-shadow: 6px 6px 25px 0px #928d8533;

  margin-bottom: 8px;
}

.review-buttons {
  display: flex;
  flex-direction: row;
  gap: 12px;
}
