@import '../../../../../scss/typography.scss';
@import '../../../../../scss/variables.scss';

.multi-choice-modal-swiper {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex !important;
  flex-direction: column;
}

.multi-choice-modal-swiper-slide {
  display: flex !important;
  flex-direction: row;
  align-self: center;
  gap: 40px;
  background: $neutral-20;
  border-radius: 8px;
  border: 2px solid $neutral-40;
  width: 1090px !important;
  height: 699px !important;
  box-shadow: none;
  padding: 40px;

  .multi-choice-modal-slide-content {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 1090px;

    .multi-choice-modal-slide-text {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 24px;

      .multi-choice-modal-text-header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        height: 24px;
        z-index: 99999;
        background-color: transparent;

        .multi-choice-modal-close {
          position: absolute;
          right: 40px;
          width: 24px;
          height: 24px;
          cursor: pointer;
        }
      }

      .multi-choice-modal-title {
        @include header-h1-thin;
        color: $neutral-100;
        line-height: 100%;
      }

      .multi-choice-modal-description {
        @include bodycopy-b1-regular;
        color: $neutral-90;
        line-height: 100%;
      }
    }

    .multi-choice-modal-content {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .empty-error-message {
        display: flex;
        align-items: center;
        height: fit-content;
        gap: 8px;

        p {
          @include bodycopy-b3-medium;
          color: $semantic-red-100;
        }

        .error-icon {
          width: 16px;
          height: 16px;

          path {
            fill: $semantic-red-100;
          }
        }
      }

      .multi-choice-modal-content-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-right: 20px;
      }

      .multi-choice-modal-tag {
        display: flex;
        flex-direction: row;
        gap: 8px;
      }

      .multi-choice-modal-profile {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 16px;
        max-height: 330px;
        width: 1006px;
        overflow-y: auto;
        padding-right: 16px;
        box-sizing: content-box;

        &::-webkit-scrollbar {
          width: 4px;

          &-track {
            background: $primary-blue-50;
            border-radius: 2px;
            border: 1px solid $neutral-20;
          }

          &-thumb {
            border-radius: 2px;
            background: $primary-blue-100;
          }
        }
      }
    }

    .multi-choice-modal-footer {
      position: absolute;
      display: flex;
      align-self: flex-end;
      right: 40px;
      bottom: 34px;
      gap: 12px;
    }
  }
}
