@import '../../scss/typography.scss';
@import '../../scss/variables.scss';

.info-container {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .image-wrapper {
    min-height: calc(450px - 88px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .page-title {
      z-index: 1;
      @include header-h1-thin;
      color: $neutral-00;
    }

    .background-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      max-height: 100%;
      object-fit: cover;
      height: 450px;
    }
  }

  .text-wrapper {
    display: flex;
    flex-direction: column;
    padding: 120px 287px 120px 287px;
    justify-content: center;

    p,
    em {
      @include bodycopy-b1-regular;
      color: $neutral-90;
      margin-top: 0px;
      margin-bottom: 0px;

      br {
        content: '  ';
        display: block;
        height: 0px;
      }
    }

    strong {
      @include bodycopy-b1-semibold;
      color: $neutral-90;
      margin-top: 0px;
    }

    br {
      content: '';
      display: block;
      height: 8px;
    }
  }
}
