@import '../../scss/variables.scss';

.image-container-header {
  position: absolute;
  width: 100%;
  // height: 100%;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: self-start;
  z-index: 1001;
  padding: 24px 40px 16px 40px;

  &-left {
    display: flex;
    flex-direction: column;
    gap: 43px;

    .logo-button {
      align-self: flex-start;
      background-color: transparent;
      border: none;
      pointer-events: none;
      height: 28px;

      &-clickable {
        @extend .logo-button;
        cursor: pointer;
        pointer-events: all;
      }
    }

    .filters-pills {
      padding-left: 8px;
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }
  }

  &-right {
    // height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 36px;
  }

  &--map-tools {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}

.image-container-footer {
  position: absolute;
  width: 100%;
  bottom: 48px;
  right: 40px;
  display: flex;
  justify-content: flex-end;
  z-index: 1000;
  pointer-events: none;
}

.full-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  background-color: $neutral-100;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 1000;
}

.play-pause-button {
  position: absolute;
  bottom: 48px;
  right: 164px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  z-index: 1001;
}

.display-toggle {
  position: absolute;
  background-color: transparent;
  top: 48%;
  right: 48%;
  display: flex;
  z-index: 1001;
}
