@import '../../scss/typography.scss';
@import '../../scss/variables.scss';

.row {
  display: grid;
  grid-template: 1fr / repeat(auto-fit, minmax(0, 1fr));
  grid-gap: 12px;
}

.houseTypeList {
  display: grid;
  grid-template: 1fr / 1fr;
  grid-gap: 16px;
}

.multiple-questions {
  display: flex;
  flex-direction: column;
  gap: 56px;
}

.title-answers {
  display: flex;
  flex-direction: column;
  gap: 24px;
  @include bodycopy-b1-semibold;
  color: $neutral-90;
  line-height: 100%;
}

.intimateContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 160px;
}

.materialContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 56px;

  .materialWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;

    .title {
      @include bodycopy-b1-semibold;
      color: $neutral-90;
    }

    .spaceBetweenRow {
      display: flex;
      justify-content: space-between;

      .packLabel {
        @include bodycopy-b2-medium;
        color: $neutral-90;
      }
    }
  }
}

.packsSection {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
