@import '../../../scss/typography.scss';
@import '../../../scss/variables.scss';

.radio-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
  padding-bottom: 16px;
}

.radio-item input {
  min-height: 20px;
  min-width: 20px;
  border: 2px solid $neutral-60;
  border-radius: 3px;

  &:checked {
    accent-color: $primary-blue-100;
  }

  &:focus {
    //TODO
  }

  &:disabled {
    border: 2px solid $neutral-50;
    accent-color: $neutral-50;
  }

  &.error {
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1.5px solid $semantic-red-100;
    outline: none;
    background-color: $semantic-red-05;
    position: relative;
  }
}

.radio-item-label {
  @include bodycopy-b2-medium;
  color: $neutral-80;

  &.checked {
    color: $neutral-90;
  }

  &.disabled {
    color: $neutral-70;
  }
}
