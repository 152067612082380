@import '../../scss/typography.scss';
@import '../../scss/variables.scss';

.client-otp-request-container {
  height: 100%;

  .client-otp-request-wrapper {
    display: flex;
    justify-content: flex-end;
    height: 100%;

    .client-otp-request-content-wrapper {
      display: flex;
      width: 51%;
      justify-content: center;
      align-items: center;
      height: 100vh;
      padding: 88px 169px 0px 169px;

      .client-otp-request-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 396px;
        overflow-y: auto;
        gap: 56px;

        .back-button {
          display: inline-flex;
          align-items: center;
          cursor: pointer;
        }

        &::-webkit-scrollbar {
          display: none;
        }

        .client-otp-request-content-header {
          display: flex;
          flex-direction: column;
          gap: 40px;

          &-title {
            @include header-h1-thin;
            color: $neutral-90;
          }

          &-description {
            @include bodycopy-b1-regular;
            color: $neutral-90;
          }
        }

        .client-otp-request-content-body {
          display: flex;
          flex-direction: column;
          gap: 32px;

          .service {
            display: flex;
            flex-direction: column;
            gap: 16px;

            &-title {
              @include bodycopy-b3-regular;
              color: $neutral-80;
            }

            &-list {
              display: flex;
              flex-direction: row;
              gap: 24px;
            }
          }
        }

        .client-otp-request-content-footer {
          display: flex;
          flex-direction: column;
          gap: 32px;
          padding-bottom: 40px;

          &-button {
            align-self: flex-start;
          }
        }
      }
    }

    &-background-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 49%;
      height: 100%;
      z-index: -1;
      object-fit: cover;
    }
  }
}
