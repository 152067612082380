@import '../../../scss/typography.scss';
@import '../../../scss/variables.scss';

.notification {
  display: flex;
  background-color: $neutral-100;
  padding: 16px 12px;
  border-radius: 4px;
  border: 1px solid $neutral-80;
  width: calc(100% - 60px); // Calculates the width to be 100% minus 60px (30px on each side)
  height: fit-content; //48px
  position: fixed;
  top: 32px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000000;
  animation: fadeIn 0.3s ease;

  &-error {
    @extend .notification;
    background-color: $semantic-red-100;
    border: 1px solid $semantic-red-75;
  }

  &-informational {
    @extend .notification;
    background-color: $primary-blue-100;
    border: 1px solid $primary-blue-75;
  }

  &-toast {
    @extend .notification;
    align-self: flex-end;
    width: calc(33.3% - 100px);
    left: 83.7%;
    background-color: $neutral-100;
    border: 1px solid $neutral-80;
  }

  &-toast-error {
    @extend .notification;
    align-self: flex-end;
    width: calc(33.3% - 100px);
    left: 83.7%;
    background-color: $semantic-red-100;
    border: 1px solid $semantic-red-75;
  }

  &-toast-informational {
    @extend .notification;
    align-self: flex-end;
    width: calc(33.3% - 100px);
    left: 83.7%;
    background-color: $primary-blue-100;
    border: 1px solid $primary-blue-75;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateX(-50%) translateY(16px);
    }
    to {
      opacity: 1;
      transform: translateX(-50%) translateY(0);
    }
  }

  .notification-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 2px;
  }

  .message {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 8px;

    width: 100%;

    .left {
      display: flex;
      align-items: flex-start;

      > svg {
        margin-right: 8px;
        width: 16px;
        height: 16px;
        path {
          fill: $neutral-00;
        }
      }
      .message-text {
        @include bodycopy-b3-semibold;
        color: $neutral-00;
      }
    }

    .right {
      cursor: pointer;
      > svg {
        width: 16px;
        height: 16px;
        path {
          fill: $neutral-60;
        }
      }
    }
  }
}

.message-description {
  @include bodycopy-b3-medium;
  color: $neutral-60;

  &-error {
    @include bodycopy-b3-medium;
    color: $semantic-red-30;
  }

  &-informational {
    @include bodycopy-b3-medium;
    color: $semantic-blue-30;
  }
}
