@import '../../../scss/variables.scss';

.zoom-controls {
  margin-bottom: 41px;
  display: flex;
  flex-direction: column;
  background-color: rgba(132, 129, 123, 0.2);
  border-radius: 4px;
  border: 1px solid $neutral-30;
  box-shadow: 2px 2px 15px 0px rgba(175, 172, 167, 0.2);
  pointer-events: all;

  &:hover {
    // box-shadow: 0px 1px 3px 0px rgba(25, 30, 22, 0.2);
    box-shadow: 2px 2px 15px 0px rgba(175, 172, 167, 0.2);

    .zoom-controls-divider {
      background-color: transparent;
    }
  }

  &:active {
    box-shadow: none;

    .zoom-controls-divider {
      background-color: transparent;
    }
  }

  &-divider {
    align-self: center;
    background-color: $neutral-40;
    height: 1px;
    width: calc(100% - 8px);
  }

  &-button-plus {
    background-color: transparent;
    border: none;
    cursor: pointer;
    height: 27px;
    width: 28px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;

    svg {
      height: 16px;
      width: 16px;

      path {
        fill: $neutral-00;
      }
    }

    &:hover {
      background-color: $neutral-20;

      svg {
        height: 16px;
        width: 16px;

        path {
          fill: $neutral-90;
        }
      }
    }

    &:active {
      background-color: $neutral-30;

      svg {
        height: 16px;
        width: 16px;

        path {
          fill: $neutral-90;
        }
      }
    }
  }

  &-button-minus {
    background-color: transparent;
    border: none;
    cursor: pointer;
    height: 27px;
    width: 28px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;

    svg {
      height: 16px;
      width: 16px;

      path {
        fill: $neutral-00;
      }
    }

    &:hover {
      background-color: $neutral-20;

      svg {
        height: 16px;
        width: 16px;

        path {
          fill: $neutral-90;
        }
      }
    }

    &:active {
      background-color: $neutral-30;

      svg {
        height: 16px;
        width: 16px;

        path {
          fill: $neutral-90;
        }
      }
    }
  }
}
