#viewerContainer {
  width: 100%;
  height: 100%;
  position: relative;
}

.overlay-svg-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* Allow interactions to pass through to the viewer */
  z-index: 10; /* Ensure it's above the image */
}

.overlay-svg-container svg {
  width: 100%;
  height: 100%;
  transform: matrix(1, 0, 0, 1, 0, 0); /* Identity transform */
  transform-origin: center;
  transform-style: preserve-3d;
}

.overlay-svg-container svg path {
  pointer-events: all; /* Enable mouse events on paths */
}

.tooltip {
  position: absolute;
  z-index: 1000;
}
