@import '../../../../../scss/typography.scss';
@import '../../../../../scss/variables.scss';

.premium-dialog {
  display: flex;
  flex-direction: row;
  gap: 24px;
  width: 1090px;
  background-color: $neutral-20;
  padding: 32px 24px 24px 24px;
  border: 2px solid $neutral-40;
  border-radius: 8px;

  .image-wrapper {
    display: flex;
    min-width: 575px;
    height: 619px;
    background-color: $neutral-20;
  }

  .image-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.premium-dialog-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 32px;
}

.premium-dialog-header {
  display: flex;
  flex-direction: column;
  gap: 32px;

  .premium-dialog-header-text-left {
    padding-top: 48px;

    .title {
      @include header-h2-thin;
      color: $neutral-90;
    }
  }

  .premium-dialog-header-text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;

    &-left {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .id {
        @include bodycopy-b1-semibold-caps;
        color: $neutral-70;
      }

      .title {
        @include header-h2-thin;
        color: $neutral-90;
      }
    }

    .close {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  .description-markdown {
    p,
    em {
      @include bodycopy-b1-regular;
      color: $neutral-90;
      margin-top: 0px;
      margin-bottom: 0px;

      br {
        content: '';
        display: block;
        height: 0px;
      }
    }

    strong {
      @include bodycopy-b1-semibold;
      color: $neutral-90;
      margin-top: 0px;
    }

    br {
      content: '';
      display: block;
      height: 8px;
    }
  }
}

.premium-dialog-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 12px;
}
