/* src/Portal.css */
.portal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  // align-items: flex-end;
  justify-content: flex-end;
  z-index: 9999;

  &-blur {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    backdrop-filter: blur(10px);
  }
}

.portal-content-blur {
  background: transparent;
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  // border-radius: 8px;
  // box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.portal-content {
  height: 100vh; /* Full viewport height */
  width: 33%;
  position: relative; /* Needed for absolute positioning of the left div */
  overflow: auto;
}
