@import '../../scss/variables.scss';
@import '../../scss/typography.scss';

.enterprise-container {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.split-screen {
  display: flex;
  flex: 1;
  width: 100%;
  height: calc(100vh - 88px); // Ajusta ao espaço restante abaixo do header
}
.left-section,
.right-section {
  position: relative;
  flex: 1;
  cursor: pointer;
  overflow: hidden;
}

.split-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 1s ease;
}

.left-section:hover .split-image,
.right-section:hover .split-image {
  transform: scale(1.35);
  filter: blur(2px) brightness(0.6);
}

.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.7);
}

.overlay-text {
  position: relative;
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 1s ease,
    visibility 1s ease;
  margin-top: 56px;
}

.overlay-text span {
  @include header-h3-medium;
}

.left-section:hover .overlay-text,
.right-section:hover .overlay-text {
  opacity: 1;
  visibility: visible;
}
