@import '../../../../scss/typography.scss';
@import '../../../../scss/variables.scss';

.dialog {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 644px;
  background-color: $neutral-20;
  padding: 32px 24px 24px 24px;
  border: 2px solid $neutral-40;
  border-radius: 8px;
}

.dialog-content {
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.dialog-image {
  display: flex;
  flex-direction: row;
  gap: 24px;
  width: 866px;
  background-color: $neutral-20;
  padding: 32px 24px 24px 24px;
  border: 2px solid $neutral-40;
  border-radius: 8px;

  .image-wrapper {
    height: 397px;
    min-width: 397px;

    > img {
      height: 100%;
      width: 100%;
    }
  }
}

.dialog-image-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 32px;
}

.dialog-header {
  display: flex;
  flex-direction: column;
  gap: 32px;

  .dialog-header-image-text-left {
    padding-top: 48px;

    .title {
      @include header-h2-thin;
      color: $neutral-90;
    }
  }

  .dialog-header-text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;

    &-left {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .id {
        @include bodycopy-b1-semibold-caps;
        color: $neutral-70;
      }

      .title {
        @include header-h2-thin;
        color: $neutral-90;
      }
    }

    .close {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  .subtitle {
    @include bodycopy-b1-regular;
    color: $neutral-90;
  }
}

.dialog-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 12px;
}
