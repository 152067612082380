@import '../../../scss/typography.scss';
@import '../../../scss/variables.scss';

.dropdown-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 8px;

  .errorMessage {
    @include bodycopy-b3-regular;
    height: $font-size-b3;
    color: $semantic-red-100;
  }
}

.dropdown-label-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 2px;

  label {
    @include bodycopy-b3-regular;
    color: $neutral-80;
  }

  span {
    @include bodycopy-b3-regular;
    color: $neutral-80;
    font-style: italic;
  }
}

.dropdown-box-close {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  height: 51px;
  width: 100%;
  border: 1px solid $neutral-60;
  border-radius: 4px;

  padding: 14px 16px 14px 16px;

  &.error {
    padding: 13px 15px; // Adjust padding for border thickness on focus
    border-width: 2px;
    border-color: $semantic-red-75;
    outline: none;
    &:hover {
      border-color: $semantic-red-75;
    }
    &:focus-within {
      border-color: $semantic-red-75;
    }
  }

  svg {
    height: 16px;
    width: 16px;
    transform: rotate(180deg);
    path {
      fill: $neutral-90;
    }
  }

  &:focus-within {
    padding: 13px 15px 13px 15px; // Adjust padding for border thickness on focus
    border-width: 2px;
    border-color: $accessibility;
    outline: none;

    &:hover {
      border-color: $accessibility;
    }
  }

  &:hover {
    border-color: $neutral-80;
  }
}

.dropdown-box-open {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  height: 51px;
  width: 100%;
  border: 2px solid $accessibility;
  border-radius: 4px;

  padding: 14px 16px 14px 16px;

  &.error {
    padding: 13px 15px; // Adjust padding for border thickness
    border-width: 2px;
    border-color: $semantic-red-75;
    outline: none;
    &:hover {
      border-color: $semantic-red-75;
    }
    &:focus-within {
      border-color: $semantic-red-75;
    }
  }

  svg {
    height: 16px;
    width: 16px;
    path {
      fill: $neutral-90;
    }
  }

  &:focus-within {
    padding: 13px 15px 13px 15px; // Adjust padding for border thickness on focus
    border-width: 2px;
    border-color: $accessibility;
    outline: none;

    &:hover {
      border-color: $accessibility;
    }
  }
}

.item-selected {
  @include bodycopy-b1-regular;
  color: $neutral-90;
  line-height: 100%;
}

.item-placeholder {
  @include bodycopy-b1-regular;
  color: $neutral-70;
  line-height: 100%;
}

.dropdown-menu {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-height: 300px;
  overflow: auto;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }

  padding: 8px 0px 8px 0px;
  gap: 4px;

  background-color: $neutral-00;

  margin-top: 4px;
  border: 1px solid $neutral-50;
  border-radius: 4px;
  box-shadow: 2px 2px 15px 0px #afaca733;
}
