@import '../../../scss/variables.scss';
@import '../../../scss/typography.scss';

.material {
  width: 88px;
  height: 88px;
  border-radius: 5px;
  border: 1px solid $neutral-40;

  background-size: cover;
  background-position: center;

  cursor: pointer;

  position: relative;

  .check-icon {
    position: absolute;
    bottom: -5px;
    right: -7px; /* Adjusted to position on the bottom-right */
    opacity: 0;
    path:nth-of-type(1) {
      fill: $primary-blue-100;
    }
  }

  &:hover {
    border: 1px solid $neutral-30;
    box-shadow: 2.2px 2.2px 16.5px 0px #afaca733;
  }

  &.selected {
    outline: 2px solid $primary-blue-100;
    border-color: transparent;

    .check-icon {
      @extend .check-icon;
      opacity: 1;
    }

    &.disabled {
      outline: 2px solid $neutral-80;
      filter: grayscale(0.6);
      cursor: not-allowed;
      .check-icon {
        @extend .check-icon;
        path:nth-of-type(1) {
          fill: $neutral-80;
        }
        opacity: 1;
      }

      &:hover {
        box-shadow: none;
        border-color: transparent;
      }
    }
  }

  &.disabled {
    outline: 1px solid $neutral-40;
    border-color: transparent;
    cursor: not-allowed;
    filter: grayscale(0.6);

    &:hover {
      box-shadow: none;
    }
  }
}

.material-tooltip {
  pointer-events: none;
  position: relative;
  display: flex;
  width: max-content;
  background-color: $neutral-100;
  left: 25px;
  top: 8px;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid $neutral-100;
  align-items: center;

  .material-name {
    @include bodycopy-b3-regular;
    pointer-events: none;
    color: $neutral-00;
    line-height: 100%;
  }

  &::after {
    pointer-events: none;
    content: '';
    position: absolute;
    bottom: -17px;
    left: 8px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 17px solid $neutral-100;
  }
}
