@import '../../../../../scss/typography.scss';
@import '../../../../../scss/variables.scss';

.basic-modal-swiper {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex !important;
  flex-direction: column;
  overflow: visible !important;
}

.swiper-slide {
  display: flex !important;
  flex-direction: row;
  align-self: center;
  gap: 40px;
  background: $neutral-20;
  border-radius: 8px;
  transition: transform 0.3s ease-in-out;
  border: 1.8px solid $neutral-40;
  width: 981px !important;
  height: 629.1px !important;
  box-shadow: 1.8px 1.8px 27px 0px rgba(0, 0, 0, 0.15);
  padding: 36px;

  &-active {
    background: $neutral-20;
    border-radius: 8px;
    border: 2px solid $neutral-40;
    width: 1090px !important;
    height: 699px !important;
    box-shadow: none;
    padding: 40px;

    .modal-left-button,
    .modal-right-button {
      display: none;
    }
  }

  .basic-modal-swiper-slide-prev,
  .basic-modal-swiper-slide-next {
    transform: scale(0.9);
  }

  &.swiper-slide-visible.swiper-slide-next,
  &.swiper-slide-visible.swiper-slide-prev {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $neutral-60;
      opacity: 0.4;
      border-radius: 8px;
      z-index: 1;
    }
    &:hover {
      .modal-left-button {
        opacity: 1;
        pointer-events: all;
      }

      .modal-right-button {
        opacity: 1;
        pointer-events: all;
      }
    }

    .modal-left-button {
      opacity: 0;
      pointer-events: none;
      right: 5%;
      position: absolute;
      top: 50%;
      z-index: 99999;
      transform: translateY(-50%);
      transition: opacity 0.3s;
    }
    .modal-right-button {
      opacity: 0;
      pointer-events: none;
      left: 5%;
      position: absolute;
      top: 50%;
      z-index: 99999;
      transform: translateY(-50%);
      transition: opacity 0.3s;
    }

    .basic-modal-slide-content {
      width: 981px;
      .img {
        display: block;
        img {
          width: 517.5px;
          height: 557.1px;
          object-fit: cover;
        }
      }

      .basic-modal-slide-text {
        display: flex;
        flex-direction: column;
        width: 362.7px;
        gap: 32px;

        .basic-modal-text-body {
          width: 362.7px;
        }
      }
    }
  }

  .basic-modal-slide-content {
    display: flex;
    flex-direction: row;
    gap: 32px;
    width: 1090px;

    .img {
      display: block;
      img {
        width: 575px;
        height: 619px;
        object-fit: cover;
      }
    }

    .basic-modal-slide-text {
      display: flex;
      flex-direction: column;
      width: 403px;
      gap: 32px;

      .basic-modal-text-header {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        height: 24px;
        z-index: 99999;
        background-color: transparent;

        .basic-modal-close {
          width: 24px;
          height: 24px;
          cursor: pointer;
        }
      }

      .basic-modal-text-body {
        display: flex;
        flex-direction: column;
        width: 419px;
        padding-right: 16px;
        gap: 48px;
        height: 550px;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 4px;

          &-track {
            background: $primary-blue-50;
            border-radius: 2px;
            border: 1px solid $neutral-20;
          }

          &-thumb {
            border-radius: 2px;
            background: $primary-blue-100;
          }
        }

        .basic-modal-title {
          @include header-h2-thin;
          color: $neutral-100;
          line-height: 100%;
        }
        .basic-modal-subtitle {
          @include bodycopy-b1-regular;
          color: $neutral-90;
        }
      }
    }
  }
}

.swiper-pagination {
  display: flex;
  justify-content: center;
  margin-top: 24px; /* Space between Swiper and pagination */
  bottom: -(calc(30px + 30px - 8px)) !important; /* Move it 30px below the swiper container, taking in consideration height and bullet size */
  height: 30px;
}
.swiper-pagination-bullet {
  width: 8px !important;
  height: 8px !important;
  background-color: $neutral-80 !important;
  border-radius: 50px !important;
  //   mix-blend-mode: multiply !important;
  opacity: var(--swiper-pagination-bullet-inactive-opacity, none) !important;

  &-active {
    background-color: $neutral-00 !important;
  }
}

.markdown-content-big {
  p,
  em {
    @include bodycopy-b1-regular;
    color: $neutral-90;
    margin-top: 0px;
    margin-bottom: 0px;

    br {
      content: '';
      display: block;
      height: 0px;
    }
  }

  strong {
    @include bodycopy-b1-semibold;
    color: $neutral-90;
    margin-top: 0px;
  }

  br {
    content: '';
    display: block;
    height: 8px;
  }
}

.markdown-content {
  p,
  em {
    @include bodycopy-b3-regular;
    color: $neutral-90;
    margin-top: 0px;
    margin-bottom: 0px;

    br {
      content: '';
      display: block;
      height: 0px;
    }
  }

  strong {
    @include bodycopy-b3-semibold;
    color: $neutral-90;
    margin-top: 0px;
  }

  br {
    content: '';
    display: block;
    height: 8px;
  }
}
