@import '../../../../scss/typography.scss';
@import '../../../../scss/variables.scss';

.preferences-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;

  span {
    @include bodycopy-b1-medium;
    color: $neutral-80;
  }

  &:hover {
    background-color: $primary-blue-05;

    span {
      color: $neutral-90;
    }
  }

  &.selected {
    background-color: $primary-blue-05;

    span {
      color: $neutral-100;
    }

    svg {
      height: 16px;
      width: 16px;
      path {
        fill: $primary-blue-100;
      }
    }
  }
}
