@import '../../../scss/variables.scss';

.video-toggle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 20px;
  background-color: rgba(25, 30, 22, 0.3);
  backdrop-filter: blur(10px);

  svg path {
    fill: $neutral-00;
  }

  &:focus {
    outline: 2px solid $accessibility;
  }
}
