@import '../../scss/typography.scss';
@import '../../scss/variables.scss';

.review {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-top: 56px;
    padding-bottom: 56px;

    > span {
      @include bodycopy-b2-semibold-caps;
      color: $neutral-90;
    }
  }
  &__step-container {
    display: flex;
    flex-direction: column;
    border: 1px solid $neutral-40;
    border-radius: 8px;
    padding: 24px;
    gap: 16px;

    &-header {
      display: flex;
      justify-content: space-between;
    }

    &-title {
      @include bodycopy-b1-semibold;
      color: $neutral-90;
      line-height: 25.6px;
    }

    &-line {
      @include bodycopy-b2-medium;
      color: $neutral-90;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    > * {
      display: flex;
      justify-content: space-between;
    }
  }
}

.top-section {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-bottom: 56px;

  .headline {
    @include header-h1-thin;
  }
  .description {
    @include bodycopy-b1-regular;
  }
}
