@import '../../scss/variables.scss';
@import '../../scss/typography.scss';

.filter-header {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99999;
  background-color: transparent;

  padding: 16px 14px 0px 14px;
}

.filter-footer {
  display: flex;
  flex: 1 0 auto;
  justify-content: space-between;
  padding-top: 8px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 48px;
}

.filter-content {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 64px;

  .filter-title {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding-bottom: 53px;

    .headline {
      @include header-h1-thin;
    }
    .description {
      @include bodycopy-b1-regular;
    }
  }
}

.sorting-content {
  padding-left: 40px;
  padding-right: 40px;

  .sorting-title {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding-bottom: 32px;

    .headline {
      @include header-h1-thin;
    }
  }
}

.question-title {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: 56px;
  @include bodycopy-b1-semibold;
  color: $neutral-90;

  > span {
    padding-bottom: 24px;
  }
}

.dropdown {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 12px;
}

.question-information {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 24px;
  padding-bottom: 24px;
}

.bedroom-options {
  display: grid;
  grid-template: 1fr / repeat(auto-fit, minmax(0, 1fr));
  grid-gap: 12px;
}

.house-type-options {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

/* .price-range {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: space-between;
}

.price-range span {
  color: $neutral-90;
  @include bodycopy-b2-medium;
} */
