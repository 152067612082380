@import '../../scss/typography.scss';
@import '../../scss/variables.scss';

.confirm-interest-container {
  height: calc(100vh - 88px);

  .confirm-interest-wrapper {
    display: flex;
    justify-content: flex-start;
    height: 100%;

    &-image {
      position: absolute;
      width: cover;
      height: 100%;
      max-width: 50%;
      top: 0;
      right: 0;
      object-fit: cover;
      overflow: hidden;
    }

    .confirm-interest-content {
      position: relative;
      display: flex;
      flex-direction: row;
      width: 50%;
      gap: 65px;
      align-items: center;
      padding: 109px 64px 0px 64px;
      height: fit-content;
      min-height: calc(100vh - 88px);

      &-text {
        display: flex;
        flex-direction: column;
        gap: 64px;
        padding-bottom: 64px;
        width: 100%;

        &-header {
          display: flex;
          flex-direction: column;
          gap: 40px;

          .id {
            @include bodycopy-b1-semibold-caps;
            color: $neutral-70;
            line-height: 100%;
          }

          &-content {
            display: flex;
            flex-direction: column;
            gap: 32px;

            .title {
              @include header-h1-thin;
              color: $neutral-90;
            }

            .subtitle {
              @include bodycopy-b1-regular;
              color: $neutral-90;
            }
          }
        }
      }

      &-text-footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 12px;
      }
    }
  }
}
