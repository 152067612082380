@import '../../../scss/variables.scss';

.form-header {
  display: flex;
  flex-direction: column;
  // padding: 38px 40px 0px 40px;
  position: sticky;
  top: 0; /* Stick to the top */
  z-index: 9999; /* Ensure it stays above other content */
  background-color: $neutral-00;
  border-bottom: 1px solid $neutral-50;
  padding-top: 38px;

  #collapse {
    margin-top: 16px;
    margin-right: 16px;
    align-self: flex-end;
  }
}
