@import '../../../scss/typography.scss';
@import '../../../scss/variables.scss';

.pill-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 4px 8px;
  border-radius: 4px;
  width: max-content;
  background-color: $neutral-00;
  box-shadow: (0px 2px 6px 0px rgba(0, 0, 0, 0.15), 0px 1px 1px 0px rgba(0, 0, 0, 0.3));

  .label {
    @include bodycopy-b3-medium;
    color: $primary-blue-100;
    line-height: 100%;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  svg {
    height: 16px;
    width: 16px;

    path {
      fill: $primary-blue-100;
    }
  }

  &:hover {
    background-color: $primary-blue-05;
    .label {
      color: $primary-blue-75;
    }
    svg path {
      fill: $primary-blue-75;
    }
  }

  &:active {
    background-color: $primary-blue-25;
    .label {
      color: $primary-blue-100;
    }
    svg path {
      fill: $primary-blue-100;
    }
  }
}
