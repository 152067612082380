@import '../../../../scss/variables.scss';
@import '../../../../scss/typography.scss';

.profile-cell {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 5px;
  border: 1px solid $neutral-30;
  background-color: $neutral-00;
  padding: 16px;

  &:hover {
    box-shadow: 2px 2px 15px 0px rgba(175, 172, 167, 0.2);
  }

  &.selected {
    background-color: $primary-blue-05;
    border: 2px solid $primary-blue-100;
  }
}

.profile-cell-header {
  display: flex;
  flex-direction: row;
  gap: 12px;

  > img {
    width: 80px;
    height: 80px;
    border-radius: 4px;
    border: 1px solid $neutral-40;
  }

  .profile-cell-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80px;
    padding-top: 8px;

    .link {
      text-decoration: underline;
      cursor: pointer;
      color: $neutral-90;
      @include bodycopy-b3-semibold;
    }
  }

  .profile-cell-text {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &-name {
      @include header-h3-medium;
      color: $neutral-100;
      line-height: 100%;
    }

    &-jobTitle {
      @include bodycopy-b3-regular;
      color: $neutral-90;
      line-height: 100%;
    }
  }

  .profile-cell-right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
