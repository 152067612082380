@import '../../../scss/variables.scss';
@import '../../../scss/typography.scss';

.slider {
  position: relative;
  width: 100%;
}

.slider__track,
.slider__range {
  position: absolute;
  border-radius: 3px;
  height: 4px;
  z-index: 1;
}

.slider__track {
  background-color: $primary-blue-25;
  width: 100%;
}

.slider__range {
  background-color: $primary-blue-100;
  z-index: 2;
}

.slider_values {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 16px;
}

.slider__left-value,
.slider__right-value {
  color: $neutral-90;
  @include bodycopy-b2-medium;
  position: relative;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 100%;
  outline: none;
}

.thumb--left {
  left: -2px;
  z-index: 3;
}

.thumb--right {
  z-index: 4;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: $primary-blue-100;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 12px;
  width: 12px;
  margin-top: 1px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: $primary-blue-100;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 12px;
  width: 12px;
  margin-top: 1px;
  pointer-events: all;
  position: relative;
}
