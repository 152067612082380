@import '../../../scss/variables.scss';
@import '../../../scss/typography.scss';

.tooltip {
  position: absolute;
  width: max-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 12px;
  border-radius: 4px;
  z-index: 1000; /* Above everything */
  transition: opacity 0.3s;
  box-shadow: 2px 2px 15px 0px rgba(25, 30, 22, 0.15);

  .label {
    @include bodycopy-b2-semibold;
    line-height: 100%;
  }
  .status,
  .premium {
    @include bodycopy-b2-regular;
    line-height: 100%;
  }
  .premium {
    font-style: italic;
  }
}

.tooltip-available {
  @extend .tooltip;
  background-color: $primary-blue-25;
  border: 1px solid $primary-blue-75;

  .label {
    color: $primary-blue-100;
  }
  .status,
  .premium {
    color: $primary-blue-100;
  }
  &-premium {
    background-color: $semantic-orange-30;
    border: 1px solid $semantic-orange-75;
  }
}

.tooltip-negotiation {
  @extend .tooltip;
  background-color: $primary-blue-05;
  border: 1px solid $neutral-50;

  .label {
    color: $neutral-90;
  }
  .status,
  .premium {
    color: $neutral-90;
  }
}

.tooltip-not-available {
  @extend .tooltip;
  background-color: $neutral-40;
  border: 1px solid $neutral-60;

  .label {
    color: $neutral-80;
  }
  .status,
  .premium {
    color: $neutral-80;
  }
}

.tooltip-reserved {
  @extend .tooltip;
  background-color: $neutral-50;
  border: 1px solid $neutral-80;

  .label {
    color: $neutral-90;
  }
  .status,
  .premium {
    color: $neutral-90;
  }
}

.tooltip-sold {
  @extend .tooltip;
  background-color: $neutral-90;
  border: 1px solid $neutral-80;

  .label {
    color: $neutral-00;
  }
  .status,
  .premium {
    color: $neutral-00;
  }
}
