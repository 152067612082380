@import '../../../../scss/typography.scss';
@import '../../../../scss/variables.scss';

.filter-button {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  @include bodycopy-b2-semibold;
  color: $neutral-80;

  cursor: pointer;

  > svg {
    path {
      fill: $neutral-80;
    }
    width: 16px;
    height: 16px;
  }

  &.active {
    color: $neutral-90;

    > svg path {
      fill: $neutral-90;
    }
  }

  .active {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;

    background-color: $neutral-90;
    border-radius: 4px;

    > p {
      z-index: 1;
      margin: 0;
      @include bodycopy-b2-semibold;
      color: $neutral-00;
    }
  }
}
