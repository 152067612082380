@import '../../scss/variables.scss';
@import '../../scss/typography.scss';

.preferences-header {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99999;
  background-color: transparent;

  padding: 16px 14px 0px 14px;
}

.preferences-footer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 12px;
  padding-top: 8px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 48px;
}

.preferences-content {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 64px;
  display: flex;
  flex-direction: column;
  gap: 48px;

  .preferences-title {
    display: flex;
    flex-direction: column;

    @include header-h1-thin;
  }

  .preferences-section {
    display: flex;
    flex-direction: column;
    gap: 24px;
    &-title {
      @include bodycopy-b2-semibold-caps;
      color: $neutral-70;
    }
    &-content {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }
}
