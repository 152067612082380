@import '../../../../scss/typography.scss';
@import '../../../../scss/variables.scss';

.price-card {
  display: flex;
  flex-direction: column;
  background-color: $primary-blue-25;
  border-radius: 4px;
  padding: 16px;
  gap: 16px;

  &-header {
    display: flex;
    justify-content: space-between;

    &-title {
      @include bodycopy-b1-medium;
      color: $neutral-90;
      line-height: 100%;
    }
    &-price {
      @include bodycopy-b1-semibold;
      color: $neutral-90;
      line-height: 100%;
    }
  }

  &-description {
    @include bodycopy-b3-regular;
    color: $neutral-90;
    line-height: 100%;
  }
}
