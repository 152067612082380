@import '../../scss/typography.scss';
@import '../../scss/variables.scss';

.remote-container {
  position: relative;
  z-index: 2001;
  .content-wrapper {
    align-items: center;
    margin: auto;

    .content-section {
      position: fixed;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      max-width: 686px;
      height: auto;
      display: flex;
      flex-direction: column;
      padding: 40px;
      gap: 32px;
      border-radius: 8px;
      background: rgba(250, 250, 250, 0.9);

      > div {
        width: 100%;
      }

      .id {
        padding-bottom: 8px;
        @include bodycopy-b1-semibold-caps;
        color: $neutral-70;
        line-height: 100%;
      }

      .title {
        @include header-h2-thin;
        color: $neutral-90;
      }

      .bodycopy {
        p,
        em {
          @include bodycopy-b1-regular;
          color: $neutral-90;
          margin-top: 0px;
          margin-bottom: 0px;

          br {
            content: '';
            display: block;
            height: 0px;
          }
        }

        strong {
          @include bodycopy-b1-semibold;
          color: $neutral-90;
          margin-top: 0px;
        }

        br {
          content: '';
          display: block;
          height: 8px;
        }
      }

      .card {
        display: flex;
        flex-direction: column;
        background-color: $primary-blue-50;
        padding: 24px 16px;
        border-radius: 4px;

        p,
        em {
          @include bodycopy-b2-regular;
          color: $neutral-90;
          margin-top: 0px;
          margin-bottom: 0px;

          br {
            content: '';
            display: block;
            height: 0px;
          }
        }

        strong {
          @include bodycopy-b2-semibold;
          color: $neutral-90;
          margin-top: 0px;
        }

        br {
          content: '';
          display: block;
          height: 8px;
        }
      }
    }

    .background-image-container {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      .background-image {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
      .overlay {
        background: rgba(25, 30, 22, 0.5);
        position: fixed;
        width: 100%;
        height: 100%;
      }
    }
  }
}
