@import '../../../../scss/variables.scss';
@import '../../../../scss/typography.scss';

.button {
  background-color: $primary-blue-100;
  padding: 8px 24px;
  border-radius: 4px;
  min-width: 130px;
  @include bodycopy-b1-semibold;
  color: $neutral-00;
  text-align: center;
  cursor: pointer;

  > span {
    @include bodycopy-b1-regular;
    color: $neutral-00;
  }

  &:hover {
    background-color: $primary-blue-75;
  }

  &:active {
    background-color: $primary-blue-75;
    box-shadow:
      2px 2px 5px 0px #27272766 inset,
      -2px -2px 5px 0px #27272766 inset;
  }

  &:focus-visible {
    outline-offset: 2px;
    outline: 2px solid $accessibility;
  }
}

.button-destructive {
  @extend .button;
  background-color: $semantic-red-100;
  color: $neutral-00;
  border: none;

  &:hover {
    background-color: $semantic-red-75;
  }

  &:active {
    background-color: $semantic-red-75;
    box-shadow:
      2px 2px 5px 0px rgba(39, 39, 39, 0.4) inset,
      -2px -2px 5px 0px rgba(39, 39, 39, 0.4) inset;
  }

  &:focus-visible {
    outline-offset: 2px;
    outline: 2px solid $accessibility;
  }
}

.button-secondary {
  @extend .button;
  background-color: transparent;
  color: $primary-blue-100;
  border: 1px solid $primary-blue-100;

  &:hover {
    background-color: $primary-blue-05;
  }

  &:active {
    border: 1px solid $primary-blue-75;
    box-shadow: none;
  }

  &:focus-visible {
    outline-offset: 2px;
    outline: 2px solid $accessibility;
  }
}

.button-ghost {
  @extend .button;
  background-color: transparent;
  color: $neutral-80;

  &:hover {
    background-color: $neutral-20;
    color: $neutral-90;
  }

  &:active {
    color: $neutral-90;
    box-shadow: none;
  }

  &:focus-visible {
    outline-offset: 2px;
    outline: 2px solid $accessibility;
  }
}

.button-dark {
  @extend .button;
  background-color: $neutral-00;
  color: $primary-blue-100;
  border: transparent;

  &:hover {
    background-color: $neutral-00;
    color: $primary-blue-75;
  }

  &:active {
    background-color: $neutral-00;
    color: $primary-blue-75;
    box-shadow:
      2px 2px 5px 0px #27272766 inset,
      -2px -2px 5px 0px #27272766 inset;
  }

  &:focus-visible {
    outline-offset: 2px;
    outline: 2px solid $accessibility;
  }
}

.button-secondary-dark {
  @extend .button;
  background-color: $dark-secondary;
  color: $neutral-00;
  border: 1px solid $neutral-00;

  &:hover {
    border: 1px solid $neutral-40;
    box-shadow: 2px 2px 15px 0px #928d8526;
    box-shadow: 1px 1px 10px 0px #00000033;
    backdrop-filter: blur(10px);
    background-color: #ffffff99;
    color: $neutral-90;
  }

  &:active {
    border: 1px solid $neutral-50;
    background-color: $neutral-00;
    box-shadow: none;
    color: $neutral-90;
  }

  &:focus-visible {
    color: $neutral-90;
    background-color: #ffffff99;
    border: 1px solid $neutral-40;
    outline-offset: 2px;
    outline: 2px solid $accessibility;
  }
}

.button-ghost-dark {
  @extend .button;
  background-color: transparent;
  color: $neutral-00;

  &:hover {
    background-color: #191e164d;
    color: $neutral-20;
  }

  &:active {
    background-color: transparent;
    color: $neutral-00;
    box-shadow: none;
  }

  &:focus-visible {
    outline-offset: 2px;
    outline: 2px solid $accessibility;
  }
}
