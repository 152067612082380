@import '../../../scss/typography.scss';
@import '../../../scss/variables';

.menu-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: transparent;
  padding: 15px 16px 15px 16px;
  @include bodycopy-b2-regular;
  color: $neutral-90;
  text-align: left;
  border: none;
  cursor: pointer;

  &.icon {
    padding: 12px 16px 12px 16px;
  }

  svg {
    align-self: center;
    transition: transform 0.3s ease;
    width: 16px;
    height: 16px;

    path {
      color: $neutral-80;
    }
  }

  &:hover {
    background-color: $primary-blue-05;
  }

  &:active {
    background-color: $primary-blue-25;
  }

  &.selected {
    background-color: $primary-blue-25;
  }

  &.destructive {
    color: $semantic-red-100;

    &:hover {
      background-color: $semantic-red-05;
    }

    &:active {
      background-color: $semantic-red-30;
    }
  }
}
