@import '../../../../scss/typography.scss';
@import '../../../../scss/variables.scss';

.textInput {
  display: flex;
  flex-direction: column;
  gap: 8px;

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
    -webkit-text-fill-color: #000 !important;
    transition: background-color 5000s ease-in-out 0s;
  }

  .label {
    @include bodycopy-b3-regular;
    color: $neutral-80;
  }

  .input-wrapper {
    position: relative; // Allows icons to be positioned relative to the input

    &:hover .left-icon path,
    &:focus-within .left-icon path {
      fill: $neutral-80;
    }

    .left-icon {
      position: absolute;
      width: 16px;
      height: 16px;
      left: 16px;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;

      path {
        fill: $neutral-70;
      }

      &.lock {
        path {
          fill: $neutral-90;
        }
      }
    }

    .clear-icon {
      position: absolute;
      right: 16px; /* Align with input's right padding */
      top: 50%;
      transform: translateY(-50%);
      background: none;
      border: none;
      cursor: pointer;
      width: 16px;
      height: 16px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;

      path {
        fill: $neutral-80;
      }
    }

    .lock-icon {
      position: absolute;
      right: 16px; /* Align with input's right padding */
      top: 50%;
      transform: translateY(-50%);
      background: none;
      border: none;
      width: 16px;
      height: 16px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;

      path {
        fill: $neutral-90;
      }
    }

    .input {
      @include bodycopy-b1-regular;
      color: $neutral-90;
      width: 100%; // Ensures the input spans the width of the parent
      border: 1px solid $neutral-60;
      border-radius: 4px;
      padding: 14px 48px; // Padding for left and right icons
      box-sizing: border-box;
      line-height: 100%;

      &:hover {
        border-color: $neutral-80;
      }

      &:focus-within {
        padding: 13px 47px; // Adjust padding for border thickness on focus
        border-width: 2px;
        border-color: $accessibility;
        outline: none;

        &:hover {
          border-color: $accessibility;
        }
      }

      &::selection {
        background-color: $primary-blue-25;
      }

      &.error {
        padding: 13px 47px; // Adjust padding for border thickness on focus
        border-width: 2px;
        border-color: $semantic-red-75;
        outline: none;
        &:hover {
          border-color: $semantic-red-75;
        }
      }
    }
  }

  .errorMessage {
    @include bodycopy-b3-regular;
    height: $font-size-b3;
    color: $semantic-red-100;
  }
}
