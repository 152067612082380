@import '../../../scss/typography.scss';
@import '../../../scss/variables.scss';

.stepper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  padding: 0px 41px;
  gap: 8px;
  z-index: 1; // Ensures the header-stepper's border is below the steps
  @include bodycopy-b3-semibold;

  div {
    display: flex;
    align-items: center; // Center check-circle icon with text
    justify-content: center; // Center text in div
    gap: 4px;
    width: 74px;
    padding-top: 6px;
    padding-bottom: 8px;
    position: relative; // Required for z-index and alignment
    z-index: 2; // Default z-index for steps
    color: $neutral-70;
    cursor: pointer;

    &:hover {
      color: $neutral-80;
      border-bottom: 1px solid $neutral-70;
      z-index: 2; // Ensure it stays above 'stepper'
      margin-bottom: -1px;
    }

    &.active {
      color: $primary-blue-100;
      border-bottom: 2px solid $primary-blue-100;
      z-index: 2; // Same level as hover
      margin-bottom: -1px; // Negative margin to visually overlap the stepper's border

      > svg path {
        fill: $primary-blue-100;
      }
    }

    > svg {
      width: 12px;
      height: 12px;
    }
  }
}
